import { FC, ReactNode, useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectisLoggedIn } from "../../store/user/repository/selectors";
import { selectUser } from "../../store/user/repository/selectors";
import { setAuthType, setLoggedIn } from "../../store/user/repository/actions";
import {
  selectPriceList,
  selectPriceListLoading,
  selectShortPriceList,
  selectShortPriceListLoading,
} from "../../store/excel/repository/selectors";
import {
  selectProductsFile,
  selectProductsFileLoading,
} from "../../store/excel copy/repository/selectors";
import { getShortPriceList } from "../../store/excel/useCases/getShortPriceList/action";
import { getProductsFile } from "../../store/excel copy/useCases/productsFile/action";
import { getPriceList } from "../../store/excel/useCases/getPriceList/action";
import { ROUTES, ROLES, LOCAL_STORAGE, DOMAIN } from "../../constants";
import classNames from "classnames";
import i18n from "../../locales";
import { AiFillHeart } from "react-icons/ai";
import { ImCart } from "react-icons/im";
import { FaUserCog } from "react-icons/fa";
import { ImExit } from "react-icons/im";
import { BsFillArchiveFill } from "react-icons/bs";
import { MdSimCardDownload } from "react-icons/md";
import { FaTelegram } from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import { IoNotifications } from "react-icons/io5";
import Section from "../section/section";
import Popup from "../popup/popup";
import ConfirmPopup from "../confirmPopup/confirmPopup";
import Loader from "../loader/loader";
import "./profileWrapper.scss";
import { getTelegramBotLink } from "../../store/user/useCases/getTelegramBotLink/actions";

interface IProps {
  children: ReactNode;
}

const ProfileWrapper: FC<IProps> = ({ children }) => {
  const links = [
    {
      text: i18n.t("profile"),
      link: ROUTES.Profile,
      icon: <FaUserCog />,
    },
    {
      text: i18n.t("favorites"),
      link: ROUTES.Favorites,
      icon: <AiFillHeart />,
    },
    {
      text: i18n.t("cart"),
      link: ROUTES.Cart,
      icon: <ImCart />,
    },
    {
      text: i18n.t("orders"),
      link: ROUTES.Orders,
      icon: <BsFillArchiveFill />,
    },
    {
      text: i18n.t("notifications"),
      link: ROUTES.Notifications,
      icon: <GrMail />,
    },
    {
      text: i18n.t("notifyTitle"),
      link: ROUTES.NotifyProducts,
      icon: <IoNotifications />,
    },
  ];

  const [isConfirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [isDownloadVisibility, setDownloadVisibility] =
    useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectisLoggedIn);
  const user = useSelector(selectUser);
  const priceList = useSelector(selectPriceList);
  const priceListLoading = useSelector(selectPriceListLoading);
  const shortPriceList = useSelector(selectShortPriceList);
  const shortPriceListLoading = useSelector(selectShortPriceListLoading);
  const productsFile = useSelector(selectProductsFile);
  const productsFileLoading = useSelector(selectProductsFileLoading);

  const handleOpen = () => setConfirmOpen(true);
  const handleClose = () => setConfirmOpen(false);
  const onLogOut = () => {
    localStorage.removeItem(LOCAL_STORAGE.loggedIn);
    localStorage.removeItem(LOCAL_STORAGE.token);
    localStorage.removeItem(LOCAL_STORAGE.user);
    localStorage.removeItem(LOCAL_STORAGE.cart);
    dispatch(setLoggedIn(null));
    dispatch(setAuthType(null));

    handleClose();
  };

  const handlePriceListClick = () => {
    dispatch(getPriceList());
  };

  const handleShortPriceListClick = () => {
    dispatch(getShortPriceList());
  };

  const handleXMLClick = () => {
    dispatch(getProductsFile());
  };

  const handleTgNavigate = () => {
    dispatch(getTelegramBotLink());
  };

  const handleDownload = (name?: string, url?: string) => {
    if (!url) return;

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", name || "unnamed");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadXML = async (name?: string, url?: string) => {
    if (!url) return;

    const link = document.createElement('a');
    link.href = url;
    link.download = name as string;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // try {
    //   const response = await fetch(url);

    //   if (response.ok) {
    //     const data = await response.arrayBuffer();
    //     const blob = new Blob([data], { type: "application/xml" });

    //     const url = URL.createObjectURL(blob);

    //     handleDownload(name, url);
    //     URL.revokeObjectURL(url);
    //   } else {
    //     console.error("XML File uploading error:", response.statusText);
    //   }
    // } catch (error) {
    //   console.error(error);
    // }
  };

  useEffect(() => {
    handleDownload(priceList?.fileName, priceList?.fileUrl);
  }, [priceList]);

  useEffect(() => {
    handleDownload(shortPriceList?.fileName, shortPriceList?.fileUrl);
  }, [shortPriceList]);

  useEffect(() => {
    handleDownloadXML(productsFile?.fileName, productsFile?.fileUrl);
  }, [productsFile]);

  useEffect(() => {
    if (user?.roles.length === 1 && user?.roles[0].name === ROLES.user) {
      setDownloadVisibility(false);
    } else {
      setDownloadVisibility(true);
    }
  }, [user?.roles]);

  return (
    <Section className="profile_wrapper">
      <aside className="profile_wrapper__aside">
        <ul className="profile_wrapper__list">
          {links.map(({ text, link, icon }) => (
            <li key={link} className="profile_wrapper__item">
              <Link
                className={classNames("profile_wrapper__link", {
                  "profile_wrapper__link--active_state":
                    location.pathname === link,
                })}
                to={link}
              >
                <span className="font_icon">{icon}</span>
                {text}
              </Link>
            </li>
          ))}
          {isLoggedIn === "true" && isDownloadVisibility && (
            <>
              <li className="profile_wrapper__item">
                <button
                  onClick={handlePriceListClick}
                  type="button"
                  className={classNames("profile_wrapper__button", {
                    "profile_wrapper__button--loading_state": priceListLoading,
                  })}
                >
                  {priceListLoading && <Loader btnStyle />}

                  <span className="font_icon">
                    <MdSimCardDownload />
                  </span>

                  {i18n.t("downloadPriceList")}
                </button>
              </li>
              <li className="profile_wrapper__item">
                <button
                  onClick={handleShortPriceListClick}
                  type="button"
                  className={classNames("profile_wrapper__button", {
                    "profile_wrapper__button--loading_state":
                      shortPriceListLoading,
                  })}
                >
                  {shortPriceListLoading && <Loader btnStyle />}

                  <span className="font_icon">
                    <MdSimCardDownload />
                  </span>

                  {i18n.t("downloadShortPriceList")}
                </button>
              </li>
              <li className="profile_wrapper__item">
                <button
                  onClick={handleXMLClick}
                  type="button"
                  className={classNames("profile_wrapper__button", {
                    "profile_wrapper__button--loading_state":
                      productsFileLoading,
                  })}
                >
                  {productsFileLoading && <Loader btnStyle />}

                  <span className="font_icon">
                    <MdSimCardDownload />
                  </span>

                  {i18n.t("downloadXML")}
                </button>
              </li>
            </>
          )}
          <li className="profile_wrapper__item">
            <button
              onClick={handleTgNavigate}
              type="button"
              className={classNames("profile_wrapper__button", {
                "profile_wrapper__button--loading_state":
                  productsFileLoading,
              })}
            >
              <span className="font_icon">
                <FaTelegram />
              </span>

              {i18n.t("guiideByChoice")}
            </button>
          </li>
          {isLoggedIn === "true" && (
            <li className="profile_wrapper__item">
              <button
                ref={buttonRef}
                onClick={handleOpen}
                type="button"
                className="profile_wrapper__button"
              >
                <span className="font_icon">
                  <ImExit />
                </span>
                {i18n.t("logOut")}
              </button>
            </li>
          )}
        </ul>
      </aside>
      <div className="profile_wrapper__content">{children}</div>
      {isConfirmOpen && (
        <Popup
          isOpen={isConfirmOpen}
          handleClose={handleClose}
          triggerRef={buttonRef}
          size="xxs"
        >
          <ConfirmPopup
            title={i18n.t("agreeLogout")}
            onConfirm={onLogOut}
            onDecline={handleClose}
          />
        </Popup>
      )}
    </Section>
  );
};

export default ProfileWrapper;
